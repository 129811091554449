import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import { projects } from "../../portfolio.js";
import ExperienceCard from "../../components/experienceCard/ExperienceCard.js";
import Masonry from "react-responsive-masonry";
import dreamHomeImg from "../../assests/images/dreamHomeImg.png";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsCount: 3,
    };
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    if (window.innerWidth <= 768) {
      this.setState({ columnsCount: 1 });
    } else {
      this.setState({ columnsCount: 3 });
    }
  }

  render() {
    const { columnsCount } = this.state;
    const { theme } = this.props.theme;
    return (
      <div className="lg:px-32">
        <p className="min-h-[300px] text-center flex flex-col justify-center items-center">
          <p className="regular-64 text-blue-300">Projects</p>
          <p className="regular-18 text-blue-400">
            A collection of tools and sites I've created
          </p>
        </p>
        <Masonry columnsCount={columnsCount} gutter="4px">
          {projects.map((project, index) => (
            <div
              key={index}
              style={{
                position: "relative",
                minHeight: index % 2 ? "300px" : "400px",
                margin: "20px",
                backgroundColor: "#FCFCFC",
              }}
              className={` mb
              -3 transform transition-transform hover:scale-105 border-[1px] rounded-xl`}
            >
              <ExperienceCard experience={project} theme={theme} />
            </div>
          ))}
        </Masonry>
      </div>
    );
  }
}

export default Projects;
