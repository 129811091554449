import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import MyButton from "../../components/myButton/MyButton.js";
import bookList from "../../assests/csv/book-list-with-img.csv";
import { readString } from "react-papaparse";
import { readingPage } from "../../portfolio.js";

class Reading extends Component {
  constructor(props) {
    super(props);
    this.buttonActive = this.buttonActive.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.state = {
      colName: [],
      parsedData: [],
      quoteIndex: 0,
      active: "All",
      genres: [],
      searchQuery: "",
    };
  }

  componentDidMount() {
    readString(bookList, this.papaConfig);
  }

  papaConfig = {
    header: true,
    complete: (results, file) => {
      console.log("Parsing complete:", results, file, results.data);
      this.setState({ parsedData: results.data });
      this.setState({ colName: Object.keys(this.state.parsedData[0]) });
      this.extractGenres(this.state.parsedData);
    },
    download: true,
    error: (error, file) => {
      console.log("Error while parsing:", error, file);
    },
  };

  getRandomQuote = () => {
    var quoteListSize = readingPage.quotes.length;
    var min = Math.ceil(0);
    var max = Math.floor(quoteListSize - 1);
    var randomIndex = Math.floor(Math.random() * (max - min + 1) + min);
    this.setState({ quoteIndex: randomIndex });
  };

  extractGenres = (parsedData) => {
    const genresList = [];

    parsedData.forEach((d) => {
      if (d.genres) {
        const genres = d.genres.split(", ").map((genre) => genre.trim());
        genresList.push(...genres);
      }
    });

    const uniqueGenres = [...new Set(genresList)].sort();
    uniqueGenres.unshift("All");
    this.setState({ genres: uniqueGenres });
  };

  buttonActive = (name) => {
    this.setState({ active: name });
  };

  handleSearch(event) {
    this.setState({ searchQuery: event.target.value });
  }

  render() {
    const theme = this.props.theme;
    var current = this.state.active;

    const filteredBooks = this.state.parsedData.filter((book) => {
      const nameMatch = book.name
        .toLowerCase()
        .includes(this.state.searchQuery.toLowerCase());
      const categoryMatch = current === "All" || book.genres.includes(current);
      return nameMatch && categoryMatch;
    });

    return (
      <div>
        <div className="flexCenter lg:max-container relative w-full lg:pb-5 xs:pb-3">
          <div className="hide-scrollbar flex lg:h-[650px] md:h-[400px] w-full items-start justify-start overflow-hidden">
            <div className="xs:w-full flex flex-grow">
              <img
                src={require(`../../assests/images/${readingPage["cover_image_path"]}`)}
                className="w-full h-full object-cover"
                alt="Book Image"
                loading="lazy"
              />
            </div>
          </div>
          <div className="flex text-white absolute flex-col lg:py-8 py-5 pl-5 pr-7 gap-5 rounded-3xl items-center text-center ">
            <p className="flex-wrap xs:bold-20 md:bold-32 lg:bold-60 lg:max-w-[400px]">
              {readingPage["title"].toUpperCase()}
            </p>
            <p>{readingPage["intro"].toUpperCase()}</p>
          </div>
        </div>
        <div>
          <Header theme={theme} />
        </div>
        <div className="flex flex-col justify-center text-center gap-2 xs:px-3 lg:px-60">
          <div className="flex justify-center items-center">
            <MyButton
              text="Get my random quote"
              onClick={this.getRandomQuote}
              theme={theme}
            />
          </div>
          <div className="min-h-[80px] italic mt-4">
            {readingPage["quotes"][this.state.quoteIndex]}
          </div>
          <input
            type="text"
            placeholder="Search by book name! Curious if we have the same book taste"
            value={this.state.searchQuery}
            onChange={this.handleSearch}
            className="rounded-2xl px-4 my-4 border border-blue-400"
          />
          <div className="flex flex-wrap justify-center pb-8">
            {this.state.genres.map((name) => (
              <div key={name}>
                <MyButton
                  text={name}
                  onClick={() => {
                    this.buttonActive(name);
                    this.setState({ searchQuery: "" });
                  }}
                  isActive={current === name}
                  theme={theme}
                />
              </div>
            ))}
          </div>

          <div className="min-h-[300px] flex flex-wrap gap-4">
            {filteredBooks.map((book, index) => {
              return (
                <div>
                  <div
                    key={index}
                    className="flex flex-col items-center w-40"
                    style={{ backgroundColor: "rgba(245, 244, 252, 0.62)" }}
                  >
                    <img
                      src={require(`../../assests/book/${book.book_cover}`)}
                      alt={`${book.name} cover`}
                      className="w-30 h-40 object-cover mb-2 shadow-2xl"
                    />
                  </div>
                  {/* <div className="text-left w-40"> */}
                  <div className="w-40">
                    <p className="regular-14 mb-1">{book.name}</p>
                    <p className="regular-12">{book.author}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Reading;
