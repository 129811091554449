import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";
import SocialMedia from "../../components/socialMedia/SocialMedia";
/* eslint-disable jsx-a11y/accessible-emoji */

export default function Footer(props) {
  const year = new Date().getFullYear();
  return (
    <div className="footer-div">
      <Fade>
        <p
          className="footer-text"
          // style={{ color: props.theme.title }}
        >
          <p class="copyright font-EuropaLight">© {year} • Clare Dang</p>
          <SocialMedia theme={props.theme} />
        </p>
      </Fade>
    </div>
  );
}
