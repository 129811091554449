import React, { useState } from "react";
import "./App.css";
import Main from "./containers/Main";
import { ThemeProvider } from "styled-components";
import { chosenTheme } from "./theme";
import { beigeTheme, darkTheme } from "./theme";
import { GlobalStyles } from "./global";
import { Analytics } from "@vercel/analytics/react";
import { FaMoon as MoonIcon } from "react-icons/fa";
import { MdOutlineWbSunny as SunIcon } from "react-icons/md";
import Logo from "./logo.png";

function App() {
  const [theme, setTheme] = useState("light");
  const themeToggler = () => {
    theme === "light" ? setTheme("dark") : setTheme("light");
  };
  return (
    <ThemeProvider theme={theme === "light" ? beigeTheme : darkTheme}>
      <>
        <GlobalStyles />
        <div className="relative">
          {/* <div
            className="fixed lg:top-5 lg:right-12 md:top-9 md:right-10 xs:top-7 xs:right-7 z-50 w-5 h-5"
            onClick={themeToggler}
          >
            {theme === "light" ? (
              <div class="relative w-14 h-14">
                <div class="absolute w-10 h-10 bg-slate-100 hover:bg-slate-200 rounded-full flex items-center justify-center">
                  <MoonIcon />
                </div>
              </div>
            ) : (
              <div class="relative w-14 h-14">
                <div class="absolute w-10 h-10 bg-slate-700 hover:bg-gray-400 hover:text-black rounded-full flex items-center justify-center">
                  <SunIcon />
                </div>
              </div>
            )}
          </div> */}
          {/* <div className="font-Quentin regular-20 fixed lg:top-5 lg:left-7 md:top-9"> */}
          <div className="ml-8 m-2">
            {/* Clare Dang
             */}
            <div>
              <img src={Logo} className="w-10 h-10 object-cover" />
            </div>
          </div>

          <Main theme={theme} />
          <Analytics />
        </div>
      </>
    </ThemeProvider>
  );
}

export default App;
