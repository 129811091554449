// import React, { Component } from "react";
// import { NavLink, Link } from "react-router-dom";
// import { settings } from "../../portfolio.js";
// import SeoHeader from "../seoHeader/SeoHeader";

// const onMouseEnter = (event, color) => {
//   const el = event.target;
//   el.style.backgroundColor = color;
// };

// const onMouseOut = (event) => {
//   const el = event.target;
//   el.style.backgroundColor = "transparent";
// };

// class Header extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isOpen: false,
//     };
//   }

//   toggleNavbar = () => {
//     this.setState({ isOpen: !this.state.isOpen });
//   };

//   render() {
//     const theme = this.props.theme;
//     const link = settings.isSplash ? "/splash" : "home";
//     return (
//       <div className="mb-10 xs:mb-[60px] flex justify-center items-center rounded-full">
//         <SeoHeader />
//         <nav
//           className="flexBetween padding-container bg-slate-100 rounded-full fixed top-5 justify-center items-center z-50 py-3 shadow-md ring-1 ring-slate-900/5"
//           style={{ zIndex: "1000" }}
//         >
//           {/* <NavLink to={link} tag={Link} className="logo">
//             <span
//               className="logo-name regular-20"
//               style={{ color: "black", fontFamily: "Agustina Regular" }}
//             >
//               Clare Dang
//             </span>
//           </NavLink> */}
//           {/* Hamburger menu for small screens */}
//           <div className="lg:hidden">
//             <button onClick={this.toggleNavbar}>
//               <svg
//                 className="h-6 w-6 fill-current text-gray-50 hover:text-gray-50 focus:text-gray-50 focus:outline-none"
//                 viewBox="0 0 24 24"
//               >
//                 <path
//                   fillRule="evenodd"
//                   d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
//                 />
//               </svg>
//             </button>

//             {this.state.isOpen && (
//               <div>
//                 <ul className="absolute top-16 left-0 bg-slate-200 w-full text-center py-2">
//                   <li>
//                     <NavLink
//                       to="/home"
//                       tag={Link}
//                       activeStyle={{
//                         fontWeight: "bold",
//                         borderRadius: "50%",
//                         backgroundColor: "red",
//                       }}
//                       style={{ color: theme.body }}
//                       // onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
//                       onMouseOut={(event) => onMouseOut(event)}
//                     >
//                       About Me
//                     </NavLink>
//                   </li>
//                   <li>
//                     <NavLink
//                       to="/experience"
//                       tag={Link}
//                       activeStyle={{
//                         // fontWeight: "bold",
//                         borderRadius: "50%",
//                         backgroundColor: "red",
//                       }}
//                       style={{ color: theme.text }}
//                       // onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
//                       onMouseOut={(event) => onMouseOut(event)}
//                     >
//                       Project
//                     </NavLink>
//                   </li>
//                   <li>
//                     <NavLink
//                       to="/blogs"
//                       tag={Link}
//                       activeStyle={{ fontWeight: "bold" }}
//                       style={{ color: theme.text }}
//                       // onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
//                       onMouseOut={(event) => onMouseOut(event)}
//                     >
//                       Blog
//                     </NavLink>
//                   </li>
//                   <li>
//                     <NavLink
//                       to="/reading"
//                       tag={Link}
//                       activeStyle={{ fontWeight: "bold" }}
//                       style={{ color: theme.text }}
//                       className="bg-yellow-100 text-red-100"
//                       // onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
//                       onMouseOut={(event) => onMouseOut(event)}
//                     >
//                       For Bookworm
//                     </NavLink>
//                   </li>
//                 </ul>
//               </div>
//             )}
//           </div>

//           {/* For larger screens */}
//           <ul className="hidden h-full gap-12 lg:flex">
//             <li>
//               <NavLink
//                 to="/home"
//                 tag={Link}
//                 activeStyle={{ fontWeight: "bold" }}
//                 style={{ color: theme.text }}
//                 // onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
//                 onMouseOut={(event) => onMouseOut(event)}
//               >
//                 About Me
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/experience"
//                 tag={Link}
//                 activeStyle={{
//                   fontWeight: "bold",
//                   borderRadius: "50%",
//                 }}
//                 style={{ color: theme.text }}
//                 // onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
//                 onMouseOut={(event) => onMouseOut(event)}
//               >
//                 Project
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/blogs"
//                 tag={Link}
//                 activeStyle={{ fontWeight: "bold" }}
//                 style={{ color: theme.text }}
//                 // onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
//                 onMouseOut={(event) => onMouseOut(event)}
//               >
//                 Blog
//               </NavLink>
//             </li>
//             <li>
//               <NavLink
//                 to="/reading"
//                 tag={Link}
//                 activeStyle={{ fontWeight: "bold" }}
//                 // style={{ color: theme.text }}
//                 className="bg-yellow-100 px-4 py-2 rounded-full"
//                 // onMouseEnter={(event) => onMouseEnter(event, theme.highlight)}
//                 onMouseOut={(event) => onMouseOut(event)}
//               >
//                 Reading
//               </NavLink>
//             </li>
//           </ul>
//         </nav>
//       </div>
//     );
//   }
// }
// export default Header;

import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import { settings } from "../../portfolio.js";
import SeoHeader from "../seoHeader/SeoHeader";

const onMouseEnter = (event, color) => {
  const el = event.target;
  el.style.backgroundColor = color;
};

const onMouseOut = (event) => {
  const el = event.target;
  el.style.backgroundColor = "transparent";
};

class Header extends Component {
  scrollToComponent = (refName) => {
    const element = document.getElementById(refName);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  render() {
    const theme = this.props.theme;
    const link = settings.isSplash ? "/splash" : "home";
    return (
      <div className="mb-10 xs:mb-[60px] flex justify-center items-center rounded-full">
        <SeoHeader />
        <nav
          className="flexBetween padding-container rounded-full fixed top-5 justify-center items-center z-50 py-3 shadow-md ring-1 ring-slate-900/5"
          style={{
            zIndex: "1000",
            backgroundColor: "white",
          }}
        >
          <ul className="h-full xs:gap-4 lg:gap-10 gap-4 flex">
            <li>
              <NavLink
                to="/home"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.text }}
                onMouseOut={(event) => onMouseOut(event)}
              >
                About Me
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/experience"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: theme.text }}
                onMouseOut={(event) => onMouseOut(event)}
              >
                Project
              </NavLink>
              {/* <li onClick={() => this.scrollToComponent("project")}>Project</li> */}
            </li>
            <li>
              <NavLink
                to="/blogs"
                tag={Link}
                activeStyle={{
                  fontWeight: "bold",
                }}
                className="px-4 py-2 rounded-full"
                style={{ color: theme.text }}
                onMouseOut={(event) => onMouseOut(event)}
              >
                Blog
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/reading"
                tag={Link}
                activeStyle={{
                  fontWeight: "bold",
                }}
                className="px-4 py-2 rounded-full"
                onMouseOut={(event) => onMouseOut(event)}
              >
                Reading
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
export default Header;
