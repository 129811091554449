import React, { Component } from "react";
import Header from "../../components/header/Header";
import HeaderCenter from "../../components/header/HeaderCenter";
import Greeting from "../../containers/greeting/Greeting";
import Skills from "../../containers/skills/Skills";
import Experience from "../experience/Experience";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import "./HomeComponent.css";
import dreamHomeImg from "../../assests/images/dreamHomeImg.png";
import Projects from "../../containers/projects/Projects";
import Blogs from "../../containers/blogs/Blogs";

class Home extends Component {
  scrollToComponent = (refName) => {
    const element = document.getElementById(refName);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  render() {
    return (
      <div>
        {/* <nav className="sticky-navbar">
          <ul>
            <li onClick={() => this.scrollToComponent("header")}>Header</li>
            <li onClick={() => this.scrollToComponent("greeting")}>Greeting</li>
            <li onClick={() => this.scrollToComponent("projects")}>Project</li>
            <li onClick={() => this.scrollToComponent("skills")}>Skills</li>
            <li onClick={() => this.scrollToComponent("footer")}>Footer</li>
          </ul>
        </nav> */}
        <div id="header">
          <Header theme={this.props.theme} />
        </div>
        <div id="greeting">
          <Greeting theme={this.props.theme} />
        </div>
        <div id="project">
          <Projects theme={this.props.theme} />
        </div>
        <div id="blog">
          <Blogs theme={this.props.theme} />
        </div>
        <div id="footer">
          <Footer theme={this.props.theme} />
        </div>
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Home;
