import React from "react";

export default function BlogCard({ blog }) {
  return (
    <a href={blog.url} target="_blank">
      <div class="flex flex-col justify-center rounded-3xl">
        <img
          src={require(`../../assests/images/${blog.image}`)}
          alt="blog"
          className="max-w-1/2"
        />
        <div className="p-2">
          <div className="p-2">{blog.title}</div>
          <div className="flex flex-wrap gap-2">
            {blog.categories.map((catgegory) => {
              return <div className="btn-3d p-1 text-xs">{catgegory}</div>;
            })}
          </div>
        </div>
      </div>
    </a>
  );
}
