import React, { useState, useRef, useEffect } from "react";
import "./Greeting.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import { greeting } from "../../portfolio";
import { Fade } from "react-reveal";
import profileImg from "../../assests/images/me_toronto.jpg";
import Skills from "../../containers/skills/Skills";
import ScrollToSectionButton from "../../components/scrollToSectionButton/scrollToSectionButton";

export default function Greeting(props) {
  const theme = props.theme;
  const [showSkillSection, setShowSkillSection] = useState(false);
  const skillsRef = useRef(null);
  const [skillsKey, setSkillsKey] = useState(0);

  const handleSkillSection = () => {
    // Update state to show the SkillSection component
    setShowSkillSection(true);
    setSkillsKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    if (showSkillSection && skillsRef.current) {
      skillsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [showSkillSection, skillsKey]);

  return (
    <div>
      <Fade bottom duration={2000} distance="40px">
        <div>
          <div className="flex flex-col sm:flex-row lg:pt-4 lg:px-40 font-GoogleSansRegular">
            <div className="max-h-[600px] lg:w-1/2 xs:w-full flex justify-end mr-5">
              <img
                alt="Profile Image"
                src={profileImg}
                width="90%"
                className="border-[1px] border-gray-30 rounded-l-xl"
                // className="lg:p-6"
              ></img>
            </div>

            <div className="lg:w-1/2 xs:w-full flex-col flex my-6 p-8 mr-5 gap-4">
              <p className="regular-52">
                {greeting.title.map((title) => {
                  return (
                    <div className="animate-text text-blue-300" key={title}>
                      {title}
                    </div>
                  );
                })}
              </p>
              <p>
                {greeting.subTitle.map((subTitle) => {
                  return <div>{subTitle}</div>;
                })}
              </p>
              <div className="mt-3">
                <SocialMedia theme={theme} />
              </div>
            </div>
          </div>
          <ScrollToSectionButton section={"skills"} />
        </div>
      </Fade>

      {/* {showSkillSection && ( */}

      <div id="skills">
        <Skills key={skillsKey} theme={theme} ref={skillsRef} className />
      </div>
    </div>
  );
}
