import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import GithubRepoCard from "../../components/githubRepoCard/GithubRepoCard";
import Button from "../../components/button/Button";
import TopButton from "../../components/topButton/TopButton";
import { Fade } from "react-reveal";
// import { projectsHeader } from "../../portfolio.js";
import ProjectsData from "../../shared/opensource/projects.json";
import "./Projects.css";
import ProjectsImg from "./ProjectsImg";
import "react-slideshow-image/dist/styles.css";
import ShowCase from "../../components/showcase/ShowCase";

class Projects extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="projects-main">
        <Header theme={theme} />
        <div className="basic-projects">
          <Fade bottom duration={2000} distance="40px">
            <div className="projects-heading-div">
              <div className="projects-heading-img-div">
                {/* <img
											src={require(`../../assests/images/${projectsHeader["avatar_image_path"]}`)}
											alt=""
										/> */}
                <ProjectsImg theme={theme} />
                <p>hello</p>
              </div>
              <div className="projects-heading-text-div">
                {/* <h1
                  className="projects-heading-text"
                  style={{ color: theme.text }}
                >
                  {projectsHeader.title}
                </h1> */}
                {/* <p
                  className="projects-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {projectsHeader["description"]}
                </p> */}
              </div>
            </div>
          </Fade>
        </div>
        <div className="repo-cards-div-main">
          {ProjectsData.data.map((repo) => {
            return <GithubRepoCard repo={repo} theme={theme} />;
          })}
        </div>
        <div className="repo-cards-div-main">
          <ShowCase />
        </div>
        <div className="repo-cards-div-main">
          {/* {ProjectsData.data.map((repo) => {
            return <GithubRepoCard repo={repo} theme={theme} />;
          })} */}
          <h2>UI showcase 2</h2>
          <br></br>
          <a href="https://www.figma.com/proto/ukCzPrAWbl30ZA9A8T4EOj/Prototype?node-id=864%3A5432&scaling=scale-down&page-id=392%3A1028">
            SFU Snap
          </a>
          <a href="https://www.figma.com/proto/0Xw8KmTjiwUl2VHayBHUQM/StudentHack-CookhouseLab?node-id=1%3A2&scaling=contain&page-id=0%3A1">
            myInsurance
          </a>
        </div>
        <Button
          text={"More Projects"}
          className="project-button"
          href="https://github.com/claredang"
          newTab={true}
          theme={theme}
        />
        {/* <ShowCase/> */}
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Projects;
