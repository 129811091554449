import React from "react";
import "./ExperienceSection.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

class ExperienceSection extends React.Component {
  render() {
    return (
      <div>
        {/* <div className="software-skills-main-div">
              <ul className="dev-icons">
                {this.props.logos.map((box) => {
                  return (
                    <OverlayTrigger
                      key={box.title}
                      placement={"top"}
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          <strong>{box.title}</strong>
                        </Tooltip>
                      }
                    >
                    </OverlayTrigger>
                  );
                })}
              </ul>
            </div> */}
      </div>
    );
  }
}

export default ExperienceSection;
