import { CgScrollV } from "react-icons/cg";
import "./scrollToSectionButton.css";

const ScrollToSectionButton = ({ section }) => {
  const scrollToSection = () => {
    const projectSection = document.getElementById(section);
    if (projectSection) {
      projectSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <button
      className="bg-transparent border-none text-2xl cursor-pointer flex justify-center items-center my-5 mx-auto"
      onClick={scrollToSection}
    >
      <CgScrollV className="zoom-in-out-box" />
    </button>
  );
};

export default ScrollToSectionButton;
