import { pdfjs } from "react-pdf";
import dreamHomeVideo from "./assests/video/dreamHome.mp4";
import dreamHomeImg from "./assests/images/dreamHomeImg.png";
import triviaGamePdf from "./assests/pdf/triviaGame.pdf";
import triviaGameVideo from "./assests/video/triviaGame.mov";
import triviaGameImg from "./assests/images/triviaGameImg.png";
import tickTalkVideo from "./assests/video/tickTalk.mp4";
import tickTalkImg from "./assests/images/tickTalkImg3.png";
import planeDetectionImg from "./assests/images/planeDetection.png";
import salaryPredictionVideo from "./assests/video/salaryPredictionVideo.mov";
import MABFLNSPdf from "./assests/pdf/MABFLNS.pdf";
import MABFLNSVideo from "./assests/video/MABFLNS.mov";
import MABFLNSGif from "./assests/MABFLNS.gif";
import ThreeDReconstruction from "./assests/3dReconstruction.gif";
import walkingBehaviorPdf from "./assests/pdf/walkingBehavior.pdf";
import walkingBehaviorImg from "./assests/images/walkingBehaviorImg.png";
import autismPredictionPdf from "./assests/pdf/autismPrediction.pdf";
import autismPredictionImg from "./assests/images/autismPrediction.png";
import insuranceVideo from "./assests/video/insurance.mov";
import insuranceImg from "./assests/images/insuranceImg.png";
import sfuSnapVideo from "./assests/video/sfuSnap.mov";
import sfuSnapImg from "./assests/images/sfuSnapImg.png";

/* Photography */
import burradBridge from "./assests/photography/burrad-bridge.JPG";
import chinaTown from "./assests/photography/chinatown-victoria.jpg";
import christmasVan from "./assests/photography/christmas-van.JPG";
import copenhagen from "./assests/photography/copenhagen.JPG";
import copenhagen2 from "./assests/photography/copenhagen2.JPG";
import hatleyCastle from "./assests/photography/hatley-castle.jpg";
import ottawa from "./assests/photography/ottawa.jpg";
import quaiPark from "./assests/photography/quai-park-montreal.jpg";
import sfuSky from "./assests/photography/sfu-sky.JPG";
import toronto from "./assests/photography/toronto.JPG";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const settings = {
  isSplash: false, // show Splash screen or not
};

// =============================== SEO Related settings  =============================== //
const seo = {
  title: "Clare Dang",
  description: "",
  og: {
    title: "Clare Dang",
    type: "website",
    url: "https://claredang.com/#/",
  },
};

//  =============================== HOME PAGE =============================== //
const greeting = {
  title: ["Hi. I'm Clare."],
  logo_name: "",
  profile_image_text: "AI Generate Me in Vancouver",
  subTitle: [
    "I hold an Honours Computing Science degree from Simon Fraser University, Canada.",
    "My passion for computer and technology is inspired early from my childhood favourite comics book, Doraemon. I always believe that those tools can one day appear in real life with the help of technology.",
    "Concretely, I am especially passionate about software development and system infrastructure.",
  ],
  resumeLink:
    "https://drive.google.com/file/d/1G6t07JSpTrBTcBEvEGcnlvefyyL4jRQG/view?usp=sharing",
  portfolio_repository: "",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/
  {
    name: "Github",
    link: "https://github.com/claredang",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  // {
  //   name: "LinkedIn",
  //   link: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  //   fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
  //   backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  // },
  // {
  //   name: "YouTube",
  //   link: "https://www.youtube.com/channel/UC_amoXmmxSY9KusoDczDTXQ",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Gmail",
    link: "mailto:pdang@sfu.ca",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "Twitter",
  //   link: "https://twitter.com/ashutosh_1919",
  //   fontAwesomeIcon: "fa-twitter", // Reference https://fontawesome.com/icons/twitter?style=brands
  //   backgroundColor: "#1DA1F2", // Reference https://simpleicons.org/?q=twitter
  // },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/laymanbrother.19/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  // {
  //   name: "Instagram",
  //   link: "https://www.instagram.com/layman_brother/",
  //   fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
  //   backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  // },
];

//   =============================== Area of interests   ===============================
const skills = {
  data: [
    {
      title: "Data Science & AI",
      fileName: "CloudInfraImg",
      skills: [
        "Developing highly scalable production ready models for various deep learning and statistical use cases.",
        "Experience of working with Computer Vision and NLP projects.",
        "Complex quantitative modelling for dynamic forecasting and time series analysis.",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "R",
          fontAwesomeClassname: "simple-icons:r",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Jupyter",
          fontAwesomeClassname: "simple-icons:jupyter",
          style: {
            color: "#0089D6",
          },
        },
      ],
    },
    {
      title: "Web Development",
      fileName: "FullStackImg",
      skills: [
        "Building responsive and attractive UI/UX websites.",
        "Maintaining servers and website database.",
        "Designing end-to-end, integration and unit test suites for both front-end and back-end services.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NextJS",
          fontAwesomeClassname: "simple-icons:nextdotjs",
          style: {
            color: "##232F3E",
          },
        },
        {
          skillName: "Angular",
          fontAwesomeClassname: "simple-icons:angular",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:nodedotjs",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "Flask",
          fontAwesomeClassname: "simple-icons:flask",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Ruby on Rails",
          fontAwesomeClassname: "simple-icons:rubyonrails",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "##232F3E",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#2496ED",
          },
        },
        {
          skillName: "git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "System Administration",
      fileName: "DesignImg",
      skills: [
        "Administer Active Directory, Group Policies, and WSUS.",
        // "⚡ Customizing logo designs and building logos from scratch",
        "Handle Exchange, Microsoft 365.",
        "Virtualization with Hyper-V and VMWare.",
        "Schedule Backup and Disaster Recovery plans.",
        "Implement and manage data security measures.",
      ],

      softwareSkills: [
        // {
        //   skillName: "Adobe XD",
        //   fontAwesomeClassname: "simple-icons:adobexd",
        //   style: {
        //     color: "#FF2BC2",
        //   },
        // },
        // {
        //   skillName: "Figma",
        //   fontAwesomeClassname: "simple-icons:figma",
        //   style: {
        //     color: "#F24E1E",
        //   },
        // },
        // {
        //   skillName: "Adobe Illustrator",
        //   fontAwesomeClassname: "simple-icons:adobeillustrator",
        //   style: {
        //     color: "#FF7C00",
        //   },
        // },
        // {
        //   skillName: "Adobe Photoshop",
        //   fontAwesomeClassname: "simple-icons:adobephotoshop",
        //   style: {
        //     color: "#000000",
        //   },
        // },
      ],
    },
    // {
    //   title: "UI/UX Design",
    //   fileName: "DesignImg",
    //   skills: [
    //     "⚡ Designing a highly attractive user interface for mobile and web applications",
    //     // "⚡ Customizing logo designs and building logos from scratch",
    //     "⚡ Prototyping to simulate the flow of application functionalities to optimize user experience",
    //   ],

    //   softwareSkills: [
    //     {
    //       skillName: "Adobe XD",
    //       fontAwesomeClassname: "simple-icons:adobexd",
    //       style: {
    //         color: "#FF2BC2",
    //       },
    //     },
    //     {
    //       skillName: "Figma",
    //       fontAwesomeClassname: "simple-icons:figma",
    //       style: {
    //         color: "#F24E1E",
    //       },
    //     },
    //     {
    //       skillName: "Adobe Illustrator",
    //       fontAwesomeClassname: "simple-icons:adobeillustrator",
    //       style: {
    //         color: "#FF7C00",
    //       },
    //     },
    //     {
    //       skillName: "Adobe Photoshop",
    //       fontAwesomeClassname: "simple-icons:adobephotoshop",
    //       style: {
    //         color: "#000000",
    //       },
    //     },
    //   ],
    // },
  ],
};

//  =============================== EDUCATION PAGE  =============================== //
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Simon Fraser University",
      subtitle: "Computer Science Major",
      logo_path: "SFU.png",
      alt_name: "SFU",
      duration: "2019 - Present",
      descriptions: ["Bachelor of Science "],
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Machine Learning",
      subtitle: "- Andrew Ng",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    {
      title: "Deep Learning",
      subtitle: "- Andrew Ng",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/H8CPSFXAJD2G",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
  ],
};

//  =============================== PROJECT PAGE  =============================== //
const projects = [
  {
    title: "Dream Home",
    duration: "June 2020 - December 2020",
    location: "",
    description: [
      "Discover your dream interior design with personalized images, engaging quizzes, and generativeAI technology.",
    ],
    textColor: "yellow-50",
    backgroundColor: "yellow-100",
    language: [
      {
        skillName: "MongoDB",
        fontAwesomeClassname: "simple-icons:mongodb",
        style: {
          color: "#47A248",
          borderRadius: "50%",
        },
      },
      {
        skillName: "NextJS",
        fontAwesomeClassname: "simple-icons:nextdotjs",
        style: {
          color: "#232F3E",
        },
      },
      {
        skillName: "NodeJS",
        fontAwesomeClassname: "simple-icons:nodedotjs",
        style: {
          color: "#339933",
        },
      },
      {
        skillName: "AWS",
        fontAwesomeClassname: "simple-icons:amazonaws",
        style: {
          color: "#232F3E",
        },
      },
    ],
    color: "#4285F4",
    // video: `${dreamHomeVideo}`,
    image: `${dreamHomeImg}`,
    project_link: "https://github.com/claredang/dream-home",
    website_link: `https://interiorspace.site`,
    type: "Website / Mobile",
  },
  {
    title: "Ludo King | Trivia Online Games for Multi-player",
    duration: "June 2020 - December 2020",
    location: "",
    backgroundColor: "yellow-100",
    description: [
      "Trivia online game allowed multiple players to join and test their knowledge on various topics!",
      // "Rule: The game consists of 5 rounds, each of which consists of 3 turns. \n In each round, several player can choose one answer at the same time but once first player choose that, this answer will be greyed out and locked. Once all 5 rounds are completed, the player with highest score will be the winner. ",
    ],
    language: [
      {
        skillName: "Python",
        fontAwesomeClassname: "simple-icons:python",
        style: {
          // backgroundColor: "#000000",
          color: "#3776AB",
        },
      },
      {
        skillName: "Flask",
        fontAwesomeClassname: "simple-icons:flask",
        style: {
          color: "#02569B",
        },
      },
      {
        skillName: "WebSocket",
        fontAwesomeClassname: "simple-icons:socketdotio",
        style: {
          color: "#232F3E", // Removed extra #
        },
      },
    ],
    color: "#4285F4",
    linkPdf: `${triviaGamePdf}`,
    // video: `${triviaGameVideo}`,
    image: `${triviaGameImg}`,
    project_link: "https://github.com/klocquiao/Trivia-Game",
    type: "Website / Mobile",
  },
  {
    title: "Tick-Talk | Real-time Chat",
    duration: "April 2021 - May 2021",
    location: "",
    textColor: "blue-300",
    backgroundColor: "yellow-100",
    description: [
      "A real-time chat website that matching user randomly to chat.",
      "Include private chat, group chat, listening music together features.",
    ],
    language: [
      {
        skillName: "Ruby On Rails",
        fontAwesomeClassname: "simple-icons:rubyonrails",
        style: {
          // backgroundColor: "#000000",
          color: "#D30001",
        },
      },
      {
        skillName: "JavaScript",
        fontAwesomeClassname: "simple-icons:javascript",
        style: {
          backgroundColor: "#000000",
          color: "#F7DF1E",
        },
      },
      {
        skillName: "WebSocket",
        fontAwesomeClassname: "simple-icons:socketdotio",
        style: {
          color: "##232F3E",
        },
      },
      {
        skillName: "SASS",
        fontAwesomeClassname: "simple-icons:sass",
        style: {
          // backgroundColor: "#000000",
          color: "#CC6699",
        },
      },
    ],
    color: "#0879bf",
    // video: `${tickTalkVideo}`,
    image: `${tickTalkImg}`,
    project_link: "https://github.com/claredang/Tick-Talk",
    type: "Website / Mobile",
  },
  {
    title: "Multi Agent Path Finding - Large Neighborhood Search Performance",
    duration: "October - December 2022",
    location: "",
    backgroundColor: "yellow-100",
    description: [
      "Multi-Agent Path Finding (MAPF) seeks collision-free paths and optimized path cost for multiple agents in grid-like environments, with critical applications such as Amazon automated warehouse.",
      // "In my final project for an Intelligent Systems class, we experiment an anytime approach for solving MAPF using Large Neighborhood Search (LNS), which is able to compute initial solutions fast, find near-optimal solutions eventually, and scale to very large numbers of agents.",
      // "Model use: Large Neighborhood Search (LNS), Conflict Based Search (CBS), Explicit Estimation Conflict-Based Search (EECBS)",
    ],
    language: [
      {
        skillName: "Python",
        fontAwesomeClassname: "simple-icons:python",
        style: {
          // backgroundColor: "#000000",
          color: "#3776AB",
        },
      },
      {
        skillName: "C++",
        fontAwesomeClassname: "simple-icons:cplusplus",
        style: {
          color: "#00599C",
        },
      },
    ],
    color: "#9b1578",
    linkPdf: `${MABFLNSPdf}`,
    // video: `${MABFLNSVideo}`,
    gif: `${MABFLNSGif}`,
    project_link: "https://github.com/claredang/MAPF",
    type: "Data Science",
  },
  {
    title: "Plane Detection",
    duration: "November - December 2023",
    location: "",
    description: [
      "Plane Object Detection, Semantic Segmentation, and Instance Segmentation using deep CNN.",
      // "Designing and training deep convolutional neural networks that improve an object detection framework to detect planes in aerial images.",
      // "Utilized: PyTorch, Tensorflow, Detectron2",
    ],
    backgroundColor: "yellow-100",
    language: [
      {
        skillName: "Pytorch",
        fontAwesomeClassname: "simple-icons:pytorch",
        style: {
          // backgroundColor: "#000000",
          color: "#EE4C2C",
        },
      },
      {
        skillName: "Tensorflow",
        fontAwesomeClassname: "simple-icons:tensorflow",
        style: {
          color: "#FF6F00",
        },
      },
    ],
    color: "#9b1578",
    image: `${planeDetectionImg}`,
    type: "Data Science",
    project_link: "https://github.com/claredang/plane-detection",
  },
  {
    title: "3D Reconstruction",
    duration: "November - December 2023",
    location: "",
    description: [
      "Construct a 3D representation of a scene using features extracted from 2D images.",
      // "Designing and training deep convolutional neural networks that improve an object detection framework to detect planes in aerial images.",
      // "Utilized: PyTorch, Tensorflow, Detectron2",
    ],
    backgroundColor: "yellow-100",
    language: [
      {
        skillName: "Pytorch",
        fontAwesomeClassname: "simple-icons:pytorch",
        style: {
          // backgroundColor: "#000000",
          color: "#EE4C2C",
        },
      },
      {
        skillName: "Tensorflow",
        fontAwesomeClassname: "simple-icons:tensorflow",
        style: {
          color: "#FF6F00",
        },
      },
    ],
    color: "#9b1578",
    gif: `${ThreeDReconstruction}`,
    type: "Data Science",
    project_link: "https://github.com/claredang/3d-reconstruction",
  },
  {
    title: "Data Science Salary Prediction",
    duration: "November - December 2023",
    location: "",

    description: [
      "Data science field salary prediction based on user information such as location, level experience, job title.",
      // "Exploratory Data Analysis (EDA) of 5000+ data science job salary to extract main raw features and factors to train model.",
      "Utilized: PyTorch, Scikit-learn",
    ],
    language: [
      {
        skillName: "Python",
        fontAwesomeClassname: "simple-icons:python",
        style: {
          // backgroundColor: "#000000",
          color: "#3776AB",
        },
      },
      {
        skillName: "Flask",
        fontAwesomeClassname: "simple-icons:flask",
        style: {
          color: "#02569B",
        },
      },
      {
        skillName: "Jupyter",
        fontAwesomeClassname: "simple-icons:jupyter",
        style: {
          color: "#F37626",
        },
      },
    ],
    color: "#9b1578",
    video: `${salaryPredictionVideo}`,
    type: "Data Science",
    website_link: `https://datascience-salary-22.streamlit.app/`,
    project_link:
      "https://github.com/claredang/dataScience-salary-predict/tree/main",
    linkPdf:
      "https://medium.com/@claredng/salary-analyst-and-prediction-9589c1926ab1",
  },
  {
    title: "Autism Spectrum Disorder (ASD) Prediction",
    duration: "February - April 2022",
    location: "",
    description: [
      "Attemp to use different machine learning model to predict a person is likely to have Autism Spectrum Disorder or not based on their profile and symptoms.",
      "Model use: Logistic Regression, SVM, Naive Bayes, KNN, MLP, CNN",
    ],
    language: [
      {
        skillName: "Jupyter",
        fontAwesomeClassname: "simple-icons:jupyter",
        style: {
          color: "#F37626",
        },
      },
    ],
    color: "#9b1578",
    linkPdf: `${autismPredictionPdf}`,
    image: `${autismPredictionImg}`,
    project_link: "https://github.com/claredang/autism-prediction",
    type: "Data Science",
  },
  {
    title: "Analyze Walking Behavior",
    duration: "February - April 2022",
    location: "",
    description: [
      "Calculate the percentage of walking asymmetric based on users' data to prevent injuries.",
    ],
    language: [
      {
        skillName: "Python",
        fontAwesomeClassname: "simple-icons:python",
        style: {
          // backgroundColor: "#000000",
          color: "#3776AB",
        },
      },
    ],
    color: "#9b1578",
    linkPdf: `${walkingBehaviorPdf}`,
    image: `${walkingBehaviorImg}`,
    project_link: "https://github.com/claredang/walking-behavior-analyze",
    type: "Data Science",
  },
  {
    title: "myInsurance",
    duration: "",
    location: "",
    description: [
      "A website that finds customized insurance based on user's information.",
    ],
    language: [
      {
        skillName: "Figma",
        fontAwesomeClassname: "simple-icons:figma",
        style: {
          // backgroundColor: "#000000",
          color: "#F24E1E",
        },
      },
    ],
    color: "#4285F4",
    // video: `${insuranceVideo}`,
    image: `${insuranceImg}`,
    project_link:
      "https://www.figma.com/proto/0Xw8KmTjiwUl2VHayBHUQM/StudentHack-CookhouseLab?node-id=1%3A2&scaling=contain&page-id=0%3A1",
    type: "UI / UX",
  },
  {
    title: "SFU Snap",
    duration: "",
    location: "",
    description: [
      "Designed throughout academic course - User Interface Design Course.",
      "From student, for student app that provide all essential features.",
    ],
    language: [
      {
        skillName: "Figma",
        fontAwesomeClassname: "simple-icons:figma",
        style: {
          // backgroundColor: "#000000",
          color: "#F24E1E",
        },
      },
    ],
    color: "#4285F4",
    // video: `${sfuSnapVideo}`,
    image: `${sfuSnapImg}`,
    project_link:
      "https://www.figma.com/proto/ukCzPrAWbl30ZA9A8T4EOj/Prototype?node-id=864%3A5432&scaling=scale-down&page-id=392%3A1028",
    type: "UI / UX",
  },
];

//   =============================== Reading Page   =============================== //
const readingPage = {
  title: "Books I have read",
  // intro:
  //   "What is better than making some hot choco, sit by the window on a rainy day and dive into another world, a journey into the past, present and even future. ",
  intro:
    "Dive into another world, a journey into the past, present and even future. ",
  quotes: [
    "“It may be that you are not yourself luminous, but that you are a conductor of light. Some people without possessing genius have a remarkable power of stimulating it.” - Sir Arthur Conan Doyle, His Last Bow",
    '"No story sit by itself. Sometimes stories meet at corners and sometimes they cover one another completely, like stones beneath a river. " - Mitch Albom, The Five People You Meet in Heaven',
    '"I am a princess. All girls are. Even if they live in tiny old attics. Even if they dress in rags, even if they aren’t pretty, or smart, or young. They’re still princesses. " - Frances Hodgson Burnett, A Little Princess',
    '"When you want something, all the universe conspires in helping you to achieve it. " - Paulo Coelho, The Alchemist ',
    '"Love casts out fear, and gratitude can conquer pride." - Louisa May Alcott, Little Woman',
    '"Time can be a greedy thing-sometimes it steals the details for itself. " - Khaled Hosseini, The Kite Runner',
  ],
  cover_image_path: "book_cover.jpg",
};

//   =============================== Photography Page   =============================== //
const photographyPage = {};

const blogSection = {
  title: "Blogs",
  subtitle:
    "Occasionally, I craft Medium articles either during involved tasks or upon completing projects. Having discovered numerous helpful articles in Medium, I aspire for my writings to help others facing similar struggles or challenges.",
  // "Connecting world through words",
  link: "https://ashutoshhathidara.wordpress.com",
  avatar_image_path: "blogs_image.svg",
  cover_image_path: "blog_cover.jpg",
  blogs: [
    {
      title:
        "Building a Customized Chatbot System with ChatGPT API: Integration and Classification",
      url:
        "https://medium.com/@claredng/building-a-customized-chatbot-system-with-chatgpt-api-integration-and-classification-d20dc60f43b7",
      image: "chatbot.jpeg",
      categories: ["Generative Ai Use Cases", "Chatbot"],
    },
    {
      title: "Script to Rename File Image based on Folder File Name",
      image: "fileScript.jpg",
      url:
        "https://medium.com/@claredng/script-to-rename-file-image-based-on-folder-file-name-b176f03aed2d",
      categories: ["Python", "Script", "File Organization", "Folder Structure"],
    },
    {
      title: "Salary Analyst and Prediction",
      image: "salaryPrediction.jpg",
      url:
        "https://medium.com/@claredng/salary-analyst-and-prediction-9589c1926ab1",
      categories: ["Prediction Model", "Data Analysis"],
    },
  ],
};

//   =============================== Contact Page   =============================== //
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "me.jpg",
    description:
      "If you want to ask me a question, discuss potential employment opportunities or just say hi, email me and I will get back to you on my earliest convenience!",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  // experience,
  contactPageData,
  readingPage,
  photographyPage,
  blogSection,
  projects,
};
