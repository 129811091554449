import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import Projects from "../../containers/projects/Projects.js";

class Experience extends Component {
  render() {
    const { theme } = this.props;

    return (
      <div className="w-dvw">
        <Header theme={theme} />
        <Projects theme={theme} />
        <Footer theme={theme} onToggle={this.props.onToggle} />
        <TopButton theme={theme} />
      </div>
    );
  }
}

export default Experience;
