import React, { useState, useEffect } from "react";
import "./MyButton.css";

const MyButton = ({
  text,
  link,
  onClick,
  className,
  isActive = false,
  theme = {},
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const [href, setHref] = useState(link);

  useEffect(() => {
    setHref(link);
  }, [link]);

  const custom_styles = isActive ? "button-main-active" : "button-main";
  // console.log("theme: ", theme, isActive);

  return link ? (
    <div className={className}>
      <button>
        <a
          href={href}
          className={`${custom_styles} ${className} btn-3d`}
          target="_blank"
          style={{
            color: theme.body,
            // backgroundColor: theme.text,
            border: `solid 1px ${theme.body}`,
            fontFamily: "Europa Light",
            borderStyle: "solid",
            borderWidth: "1px 2px 2px 1px",
          }}
        >
          {text}
        </a>
      </button>
    </div>
  ) : (
    <div>
      <button
        onClick={handleClick}
        className={`${custom_styles} ${className} btn-3d`}
        style={{
          color: theme.body,
          // backgroundColor: theme.text,
          border: `solid 1px ${theme.text}`,
          borderRadius: "8px",
        }}
      >
        {text}
      </button>
    </div>
  );
};

export default MyButton;
