import React, { Component } from "react";
import BlogCard from "../../components/blogCard/BlogCard";
import { blogSection } from "../../portfolio";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import chatbotBlogCover from "../../assests/images/chatbotBlogCover.jpeg";
import scriptFile from "../../assests/images/scriptFile.jpeg";
import predictiveAnalyst from "../../assests/images/predictiveAnalysis.jpeg";
import loadingSpinner from "../../assests/images/spinner.svg";
// import { blogSection } from "../../portfolio.js";
const imageSources = [chatbotBlogCover, scriptFile, predictiveAnalyst];

class Blogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      loading: true,
    };
  }

  fetchArticles = async () => {
    try {
      const response = await fetch(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@claredng"
      );

      const data = await response.json();

      setTimeout(() => {
        this.setState({ articles: data.items, loading: false });
      }, 500);
    } catch (error) {
      console.error("Error fetching articles:", error);
    }

    console.log("article: ", this.state.articles);
  };

  componentDidMount() {
    this.fetchArticles();
  }

  render() {
    const theme = this.props.theme;
    console.log("blogsection: ", blogSection.blogs);
    return (
      <div>
        {/* <div className="flexCenter lg:max-container relative w-full lg:pb-5 xs:pb-3">
          <div className="hide-scrollbar flex lg:h-[650px] md:h-[400px] w-full items-center justify-center overflow-hidden bg-black">
            <div className="xs:w-full flex flex-grow min-h-350">
              <img
                src={require(`../../assests/images/${blogSection["cover_image_path"]}`)}
                className="w-full h-full object-cover"
                alt="Book Image"
                loading="lazy"
              />
            </div>
          </div>
          <div className="flex absolute flex-col lg:py-8 py-5 pl-5 pr-7 gap-5 rounded-3xl items-center text-center ">
            <p className="flex-wrap lg:max-w-[400px] text-white">
              <p className="regular-72 xs:bold-20 md:bold-32 lg:bold-60 ">
                {blogSection.title}
              </p>
              <p>{blogSection.subtitle}</p>
            </p>
          </div>
        </div> */}

        <div className="flex flex-col sm:flex-row bg-black">
          <div className="max-h-[600px] lg:w-3/5 xs:w-full flex items-center justify-center bg-black">
            <img
              alt="Blog Cover"
              src={require(`../../assests/images/${blogSection["cover_image_path"]}`)}
              className="rounded-l-xl max-h-[100%]"
            />
          </div>

          <div className="lg:w-2/5 xs:w-full flex-col flex justify-around bg-black">
            <div className="text-white m-10">
              <p className="regular-52 ml-10"> {blogSection.title}</p>
              <p className="mt-4">{blogSection.subtitle}</p>
              {/* <p className="regular-32 bg-purple-50 max-w-1/2">
                Connecting the <i>world</i> through <i>words</i>
              </p> */}
            </div>
          </div>
        </div>

        <div className="lg:px-32 pb-6" id="blogs">
          <Header theme={theme} />

          <div className="lg:py-4">
            {this.state.loading ? (
              <div className="flex items-center justify-center">
                <img src={loadingSpinner}></img>
              </div>
            ) : (
              <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5">
                {blogSection.blogs.map((blog, index) => (
                  <div
                    className="border-[1px] rounded-b-lg bg-white"
                    key={index}
                  >
                    <BlogCard blog={blog} />
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* <Footer theme={this.props.theme} onToggle={this.props.onToggle} /> */}
          <TopButton theme={this.props.theme} />
        </div>
      </div>
    );
  }
}

export default Blogs;
