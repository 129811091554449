import React, { Component, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import "./Photography.css";
import {
  imagesList,
  // imagesArray,
  images,
  photographyPage,
} from "../../portfolio.js";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

// Class component: compactible with ArrayList: <Gallery />
class Photography extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      // isOpen: 2,
      // index: -1,
    };
  }

  // handleClick = (index) => {
  //   this.setState({ index : index });
  // }

  // handleClose = () => {
  //   this.setState({ index : -1 });
  // }

  // handleMovePrev = () => {
  //   this.setState({ index : -1 });
  // }

  render() {
    const { index, isOpen } = this.state;
    const theme = this.props.theme;
    console.log("inside here");
    console.log("index value: ", this.state.index);
    console.log("open value: ", this.state.index);

    return (
      <div>
        <div className="reading-main">
          <Header theme={theme} />
          <div class="reading-content">
            <div class="button-54">{photographyPage["title"]}</div>
            <div class="reading-intro">
              <p>{photographyPage["intro"]}</p>
              {/* <Gallery images={imagesArray} onClick={() => console.log("Click here")} /> */}
            </div>
            {/* <Gallery images={imagesArray} enableImageSelection={false} /> */}
          </div>
          <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
          <TopButton theme={this.props.theme} />
        </div>
      </div>
    );
  }
}

export default Photography;

// ======================= FUNCTIONAL COMPONENTS ================= /
// // Error when use functional component: cant pass theme props
// export default function Photography() {
//   const [index, setIndex] = useState(-1);

//   const currentImage = images[index];
//   const nextIndex = (index + 1) % images.length;
//   const nextImage = images[nextIndex] || currentImage;
//   const prevIndex = (index + images.length - 1) % images.length;
//   const prevImage = images[prevIndex] || currentImage;

//   const handleClick = (index) => setIndex(index);
//   const handleClose = () => setIndex(-1);
//   const handleMovePrev = () => setIndex(prevIndex);
//   const handleMoveNext = () => setIndex(nextIndex);

//   console.log("Testing components", currentImage)

//   return (
//     // <div>
//     //   <div className="reading-main">
//     //     <Header theme={theme} />
//     //     <div class="reading-content">
//     //       <div class="reading-intro">
//     //         {/* <Gallery images={imagesList} onClick={() => console.log("Click here")} /> */}
//     //       </div>
//     //       {/* <Gallery images={imagesList} enableImageSelection={false} /> */}
//     //     </div>
//     //     <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
//     //     <TopButton theme={this.props.theme} />
//     //   </div>
//     // </div>

//     <div>
//       <p>Testing</p>
//       <Gallery
//         images={imagesList}
//         onClick={handleClick}
//         enableImageSelection={true}
//       />
//       {!!currentImage && (
//         /* @ts-ignore */
//         <Lightbox
//           mainSrc={currentImage.original}
//           imageTitle={currentImage.caption}
//           mainSrcThumbnail={currentImage.src}
//           nextSrc={nextImage.original}
//           nextSrcThumbnail={nextImage.src}
//           prevSrc={prevImage.original}
//           prevSrcThumbnail={prevImage.src}
//           onCloseRequest={handleClose}
//           onMovePrevRequest={handleMovePrev}
//           onMoveNextRequest={handleMoveNext}
//         />
//       )}
//     </div>
//   );
// }
