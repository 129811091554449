import React, { Component } from "react";
import "./Skills.css";
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import { skills } from "../../portfolio";
import { Fade } from "react-reveal";
import DataScienceImg from "./DataScienceImg";
import FullStackImg from "./FullStackImg";
import CloudInfraImg from "./CloudInfraImg";
import DesignImg from "./DesignImg";
import ExperienceSection from "../../components/experienceSection/ExperienceSection";

function GetSkillSvg(props) {
  if (props.fileName === "DataScienceImg")
    return <DataScienceImg theme={props.theme} />;
  else if (props.fileName === "FullStackImg")
    return <FullStackImg theme={props.theme} />;
  else if (props.fileName === "CloudInfraImg")
    return <CloudInfraImg theme={props.theme} />;
  return <DesignImg theme={props.theme} />;
}

class SkillSection extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="lg:flex lg:px-8 gap-5">
        {skills.data.map((skill, index) => {
          // const widthClass = index % 2 === 0 ? "w-1/3" : "w-2/3";
          const widthClass = "w-1/3";
          return (
            <div
              className={`border-[1px] flex flex-col xs:w-full min-h-[250px] lg:px-[50px] xs:p-[5px] bg-white rounded-l-5xl mb-6 lg:${widthClass} justify-center items-center`}
              key={index}
              style={{ backgroundColor: "#FCFCFC" }}
            >
              <div className="flex-col justify-center rounded-3xl gap-3">
                <Fade right duration={1000}>
                  <h1
                    className="bold-28 px-2 py-4 text-blue-330"
                    // style={{ color: theme.text }}
                  >
                    {skill.title}
                  </h1>
                </Fade>
                <Fade right duration={1500}>
                  <SoftwareSkill logos={skill.softwareSkills} />
                </Fade>
                <Fade right duration={1500}>
                  <ExperienceSection logos={skill.ExperienceCard} />
                </Fade>

                {skill.skills.map((skill, skillIndex) => (
                  <li key={skillIndex} className="m-2">
                    {skill}
                  </li>
                ))}
                {/* <Fade left duration={2000}>
                  <div className="max-w-[300px]">
                    <GetSkillSvg fileName={skill.fileName} theme={theme} />
                  </div>
                </Fade> */}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default SkillSection;
