import React, { Component } from "react";
import Modal from "react-modal";
import MyButton from "../myButton/MyButton";
import "./ExperienceCard.css";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import SoftwareSkill from "../../components/softwareSkills/SoftwareSkill";
import { Fade } from "react-reveal";
import dreamHomeImg from "../../assests/images/dreamHomeImg.png";

class ExperienceCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVideoModalOpen: false,
    };
  }

  openVideoModal = () => {
    this.setState({ isVideoModalOpen: true });
  };

  closeVideoModal = () => {
    this.setState({ isVideoModalOpen: false });
  };

  render() {
    const experience = this.props.experience;
    const theme = this.props.theme;
    let externalLink =
      experience["title"] === "SFU Snap" ||
      experience["title"] === "myInsurance"
        ? "Figma Link"
        : "GitHub";

    return (
      <div key={experience["description"]}>
        <Fade top duration={2000} distance="20px">
          <p
            className={`px-5 py-2 bold-20 text-blue-400 hover:underline hover:underline-offset-4`}
          >
            {experience["title"]}
          </p>
        </Fade>

        {experience["image"] && (
          <div
            className="h-[250px] flex lg:flex-grow"
            style={{ backgroundColor: "rgba(245, 244, 252, 0.62)" }}
          >
            <img src={`${experience["image"]}`} width="100%" alt="Experience" />
          </div>
        )}
        {experience["gif"] && (
          <div className="max-h-[400px] bg-yellow-200 flex lg:flex-grow">
            <img src={`${experience["gif"]}`} width="100%" alt="Experience" />
          </div>
        )}
        {experience["video"] && (
          <div className="w-full flex lg:flex-grow items-start">
            <button onClick={this.openVideoModal}>
              <video controls width="100%">
                <source src={`${experience["video"]}`} type="video/webm" />
                Sorry, your browser doesn't support videos.
              </video>
            </button>
            <Modal
              isOpen={this.state.isVideoModalOpen}
              onRequestClose={this.closeVideoModal}
              contentLabel="Video Modal"
              style={{
                content: {
                  width: "50vw",
                  height: "50vh",
                  top: "50%", // Center vertically
                  left: "50%", // Center horizontally
                  transform: "translate(-50%, -50%)", // Center both vertically and horizontally
                },
              }}
            >
              <video controls width="100%">
                <source src={`${experience["video"]}`} type="video/webm" />
                Sorry, your browser doesn't support videos.
              </video>
            </Modal>
          </div>
        )}
        <div className="p-5">
          {experience["description"].map((d) => (
            <div className="" key={d}>
              {d}
            </div>
          ))}

          <div className="pt-5 lg:flex">
            <SoftwareSkill logos={experience["language"]} />
          </div>
          <div className="flex py-3 flex-wrap">
            {experience["website_link"] && (
              <MyButton
                text="Website"
                link={experience["website_link"]}
                className="py-3 text-sm"
                theme={theme}
              />
            )}
            {experience["project_link"] && (
              <MyButton
                text={externalLink}
                link={experience["project_link"]}
                className="py-3 text-sm"
                theme={theme}
              />
            )}

            {experience["linkPdf"] && (
              <MyButton
                text="Read Project"
                link={experience["linkPdf"]}
                className="py-3 text-sm"
                theme={theme}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ExperienceCard;
